import styled from '@emotion/styled';
import { getColor } from 'UIUtils';
import { Text } from '../../../../../Text';

export const StyledText = styled(Text)`
  z-index: 50;
  background: ${getColor('disabled-input-background')};
  border-radius: 4px;
  padding: 2px 6px;
  height: 17px;
  vertical-align: middle;
`;
