import React, { useMemo, MouseEvent } from 'react';
import { IconContainer, Svg } from './styles';
import { IconViewProps } from './types';

export function Icon({
  onClick,
  disabled,
  hoverable = false,
  glyph,
  fill,
  color,
  ...props
}: IconViewProps) {
  let onClickHandler = useMemo(
    () =>
      !disabled && typeof onClick === 'function'
        ? (e: MouseEvent<HTMLDivElement>) => {
            if (typeof e?.stopPropagation === 'function') {
              e.preventDefault();
            }
            onClick(e);
          }
        : undefined,
    [disabled, onClick]
  );
  return (
    <IconContainer onClick={onClickHandler} color={color} {...props}>
      <Svg
        width="100%"
        height="100%"
        shapeRendering="auto"
        color={color}
        fill={fill}
        disabled={disabled}
        hoverable={hoverable}
      >
        <use xlinkHref={`#${glyph}`} />{' '}
      </Svg>{' '}
    </IconContainer>
  );
}
