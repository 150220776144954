import React, { useCallback, useMemo } from 'react';
import { Radio } from './components';
import {
  FormRadioButtonViewProps,
  RadioButtonViewProps,
  RadioButtonWithDecoratorViewProps,
  RenderButtonWrapperViewProps,
  UBTFormRadioButtonViewProps,
} from './types';
import { InputDecorator } from 'UIComponents';
import {
  StyledButton,
  Wrapper,
  ButtonsWrapper,
  ButtonsContainer,
} from './styles';
import { chunk } from './utils';
import { FieldContainer } from '../FieldContainer';
import { useFormFieldController } from 'Hooks';
import { SelectOption } from '../Select/types';

function RenderButtonWrapper({
  list,
  value,
  error,
  handleChangeButton,
  disabled,
}: RenderButtonWrapperViewProps) {
  const renderButton = useCallback(
    (item: SelectOption) => (
      <StyledButton
        key={item.value}
        error={error}
        isActive={value === item.value}
        onClick={handleChangeButton(item.value)}
        disabled={disabled}
      >
        {item.label}
      </StyledButton>
    ),
    [disabled, error, handleChangeButton, value]
  );

  return (
    <ButtonsWrapper length={list.length}>
      {list.map(renderButton)}
    </ButtonsWrapper>
  );
}

function RenderButton({
  list,
  value,
  onChange,
  disabled,
  error,
}: Omit<RadioButtonViewProps, 'asButton'>) {
  const data = useMemo(() => list && chunk(list, 3), [list]);
  const handleChangeButton = useCallback(
    (value: string) => () => {
      onChange?.(value);
    },
    [onChange]
  );

  const renderData = useCallback(
    (list: SelectOption[], index: number) => (
      <RenderButtonWrapper
        key={index}
        list={list}
        handleChangeButton={handleChangeButton}
        error={error}
        disabled={disabled}
        value={value}
      />
    ),
    [disabled, error, handleChangeButton, value]
  );

  return (
    <ButtonsContainer error={error} length={data?.length}>
      {data?.map(renderData)}
    </ButtonsContainer>
  );
}

function RenderRadio({
  list,
  value,
  onChange,
  error,
  disabled,
  inline = false,
  options,
}: Omit<RadioButtonViewProps, 'asButton'>) {
  const handlerChange = useCallback(
    (value: string) => {
      onChange?.(value);
    },
    [onChange]
  );

  const renderButton = useCallback(
    (item: SelectOption) => (
      <Radio
        {...item}
        key={item.value}
        disabled={disabled}
        checked={value === item.value}
        error={error}
        onChange={handlerChange}
        options={options}
      />
    ),
    [disabled, error, handlerChange, value, options]
  );

  return <Wrapper row={inline}>{list?.map(renderButton)}</Wrapper>;
}

export function RadioButton({ asButton, ...props }: RadioButtonViewProps) {
  return asButton ? <RenderButton {...props} /> : <RenderRadio {...props} />;
}

export function RadioButtonWithDecorator({
  label,
  error,
  ...props
}: RadioButtonWithDecoratorViewProps) {
  return (
    <InputDecorator label={label} error={error} {...props} requiredInHeader>
      <RadioButton error={!!error} {...props} />
    </InputDecorator>
  );
}

export function UBTFormRadioButton({
  field,
  value,
  isVisible,
  error,
}: UBTFormRadioButtonViewProps) {
  const list = useMemo(
    () =>
      field.field_select_values?.reduce((acc, value) => {
        return [
          ...acc,
          { label: value.name, value: value.value || value.name },
        ];
      }, [] as NonNullable<RadioButtonViewProps['list']>),
    [field.field_select_values]
  );

  return (
    <FieldContainer hidden={!isVisible} halfWidth={field.options?.half_width}>
      <RadioButtonWithDecorator
        label={field.name}
        value={value}
        error={error}
        list={list}
        inline={!!field.options?.inline}
        options={field.options}
        asButton={!!field.options?.as_button}
      />
    </FieldContainer>
  );
}

export function FormRadioButton({ field }: FormRadioButtonViewProps) {
  const { ref, value, onChange, error, label } = useFormFieldController(field);

  const list = useMemo(
    () =>
      field.field_select_values?.reduce((acc, value) => {
        return [
          ...acc,
          { label: value.name, value: value.value || value.name },
        ];
      }, [] as SelectOption[]),
    [field.field_select_values]
  );

  return (
    <FieldContainer
      ref={ref}
      hidden={!field.isVisible}
      halfWidth={field.options?.half_width}
    >
      <RadioButtonWithDecorator
        label={label}
        value={value}
        onChange={onChange}
        error={error}
        list={list}
        inline={!!field.options?.inline}
        asButton={!!field.options?.as_button}
        options={field.options}
      />
    </FieldContainer>
  );
}
